<template>
  <div class="login_main">
    <div class="login_bg"></div>
    <el-form class="login_form" :model="form" ref="loginForm" :rules="rules">
      <h3>统一管理平台</h3>
      <el-form-item prop="phone">
        <el-input
          v-model="form.phone"
          size="medium"
          maxlength="11"
          prefix-icon="el-icon-user"
          placeholder="请输入手机号"
          clearable
        />
      </el-form-item>
      <el-row>
        <el-col :span="14">
          <el-form-item prop="code">
            <el-input
              size="medium"
              :maxlength="6"
              placeholder="请输入验证码"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model="form.code"
            />
          </el-form-item>
        </el-col>
        <el-col :push="2" :span="8">
          <el-button :disabled="isDisabled" size="medium" @click="handleGetCode">
            {{ count > 0 ? count + "s 后重试" : "获取验证码" }}
          </el-button>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button
          @click="handleLogin"
          size="medium"
          style="width: 100%"
          type="primary"
          :loading="loading"
        >
          {{ loading ? "登录中..." : "登录" }}
        </el-button>
      </el-form-item>
    </el-form>
    <div class="copyright">
      <!-- © Copyright {{ domainNameData?.copyright || '2015-2021' }} .{{
        domainNameData?.fullName || '上海烈熊网络技术有限公司'
      }}
      — All Rights Reservered &nbsp;&nbsp;|&nbsp;&nbsp; -->
      <a href="https://beian.miit.gov.cn/" target="_blank">
        {{ domainNameData?.websiteId || "沪ICP备15043205号-7" }}
      </a>
      <!--      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-->
      <!--      <a href="https://beian.miit.gov.cn/" target="_blank">-->
      <!--        沪ICP备15043205号-->
      <!--      </a>-->
    </div>
  </div>
</template>

<script>
import { loginApi } from "@/api";
import { mapActions } from "vuex";
import { DOMAIN_NAME } from "@/constants/user-status.ts";
export default {
  data() {
    return {
      loading: false,
      disabled: true,
      count: 0,
      form: {
        phone: "",
        code: "",
      },
      rules: {
        phone: [
          { required: true, message: "请输入手机号" },
          {
            pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
            message: "请输入正确的手机号",
          },
        ],
        code: [
          { required: true, message: "请输入验证码" },
        ],
      },
    };
  },
  watch: {
    "form.phone": function () {
      this.$refs["loginForm"].validateField("phone", (errMsg) => {
        this.disabled = errMsg !== "";
      });
    },
  },
  computed: {
    isDisabled() {
      return this.disabled || this.count > 0;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    domainNameData() {
      const { data } = DOMAIN_NAME.find((item) => {
        if (window.location.host.includes(item.hostname)) {
          return `${item}`;
        }
      }) || { data: "" };
      console.log("数据", data);
      return data;
    },
  },
  methods: {
    ...mapActions(["login"]),
    handleGetCode() {
      if (this.count <= 0) {
        this.count = 59;
        const timeId = setInterval(() => {
          if (this.count <= 0) {
            clearInterval(timeId);
          } else {
            this.count--;
          }
        }, 1000);
        // 获取手机验证码
        loginApi.getCode(this.form.phone).then((res) => {
          if (res.success) {
            this.$message.success("验证码已发送");
          }
        });
      }
    },
    handleLogin() {
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.login(this.form)
            .then(() => {
              this.$router.replace({
                path: "/home",
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login_main {
  height: 100%;
  .copyright {
    color: #666;
    font-size: 14px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    line-height: 60px;
    text-align: center;
    a {
      color: #666;
      cursor: pointer;
      text-decoration: none;
      &:hover {
        color: #03a9f4;
      }
    }
  }
  .login_bg {
    height: 35%;
    background: #03a9f4;
  }
  .login_form {
    max-width: 460px;
    margin: 0 auto;
    padding: 30px 60px;
    box-sizing: border-box;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
    position: relative;
    top: -100px;
    background: #fff;
    border-radius: 4px;
    h3 {
      margin-bottom: 40px;
    }
  }
}
</style>
